import React from 'react';
import * as styles from './flightpath.module.css';
import image from "../../images//flightpath-desk.png";

const Flightpath = () => {
  return (
    <div className={styles.container} >
      <img className={styles.backgroundImage} src={image} alt='Purple Arch'/>
    </div>
  );
};

export default Flightpath;
