import React from 'react';
import Layout from '../components/Layout';
import * as styles from './spaceflight.module.css';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import ReadyToBecomeAstronautSection from '../components/ReadyToBecomeAstronautSection';
import OurPilotsSection from '../components/OurPilotsSection';
import TypographyBold from '../components/TypographyBold';
import PreviewSectionInner from '../components/PreviewSectionInner';
import SpaceflightSection from '../components/SpaceflightSection';
import GallerySection from '../components/GallerySection';
import WorldClassSafetySection from '../components/WorldClassSafetySection/world-class-safety-section';
import video from './videos/DB_Feather.mp4';
import UnityVideoSection from '../components/UnityVideoSection';

const SpaceflightPage = () => {
  return (
    <Layout pageTitle='Virgin Galactic | Spaceflight' description='A Virgin Galactic spaceflight combines the thrill of a high-G rocket ride with the boundless wonder and awe of floating in space.'>
      <section className={styles.previewSection}>
        <video className={styles.video} autoPlay loop muted playsInline>
          <source src={video} type='video/mp4' />
        </video>
        <Container className={styles.container} size='sm'>
          <PreviewSectionInner>
            <div className={styles.pageTitleWrapper}>
              <PageTitle>
                A first-of-its-kind flight.
                <br/>
                <TypographyBold> A one-of-a-kind experience.</TypographyBold>
              </PageTitle>
            </div>
          </PreviewSectionInner>
        </Container>
      </section>
      <SpaceflightSection />
      <GallerySection />
      <UnityVideoSection />
      <OurPilotsSection />
      <WorldClassSafetySection />
      <ReadyToBecomeAstronautSection />
    </Layout>
  );
};

export default SpaceflightPage;
