// extracted by mini-css-extract-plugin
export var pilotsSection = "pilots-gallery-module--pilotsSection--wCH9S";
export var container = "pilots-gallery-module--container--+Vx5k";
export var slideList = "pilots-gallery-module--slide-list--8UqOz";
export var sliderContainer = "pilots-gallery-module--slider-container--pi1OK";
export var slickContainer = "pilots-gallery-module--slick-container---A6FE";
export var slick = "pilots-gallery-module--slick--WiYyV";
export var slickButton = "pilots-gallery-module--slick-button--ddQ7h";
export var slickButtonActive = "pilots-gallery-module--slick-button-active--0um-J";
export var sliderElements = "pilots-gallery-module--slider-elements--XxQ-l";
export var sliderButton = "pilots-gallery-module--slider-button---1qR3";
export var sliderLeftButton = "pilots-gallery-module--slider-left-button--9s+i2";
export var sliderRightButton = "pilots-gallery-module--slider-right-button--ebwl+";
export var slideInner = "pilots-gallery-module--slide-inner--vtUit";
export var slideImageWrapper = "pilots-gallery-module--slide-image-wrapper--gDW5c";
export var slideImage = "pilots-gallery-module--slide-image--46HyS";
export var slideInfo = "pilots-gallery-module--slide-info--ZWJcu";
export var header = "pilots-gallery-module--header--YkR9u";
export var name = "pilots-gallery-module--name--0Dcs1";
export var role = "pilots-gallery-module--role--JFVBl";
export var infoRow = "pilots-gallery-module--info-row--Reeqx";
export var flyingSince = "pilots-gallery-module--flying-since--coUaT";
export var noOfTypesFlown = "pilots-gallery-module--no-of-types-flown--qjBUE";
export var totalExperience = "pilots-gallery-module--total-experience--RWqi8";
export var detailedNoOfTypesFlown = "pilots-gallery-module--detailed-no-of-types-flown--ph9rv";
export var infoRowTitle = "pilots-gallery-module--info-row-title--KkGRd";
export var mainTypesFlown = "pilots-gallery-module--main-types-flown--ImadM";
export var mainTypesFlownValues = "pilots-gallery-module--main-types-flown-values--I2R9-";