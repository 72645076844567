import React from "react";
import * as styles from "./our-pilots-section.module.css";
import Container from "../Container";
import SectionTitle from "../SectionTitle";
import PilotsGallery from "./components/PilotsGallery";
import slideImage1 from "./images/pilot-Dave.png";
import slideImage2 from "./images/pilot-Kelly.png";
import slideImage3 from "./images/pilot-Nicola.png";
import slideImage5 from "./images/pilot-Mike.png";
import slideImage6 from "./images/pilot-Andy.png";
import slideImage7 from "./images/pilot-Jameel.png";
import slideImage8 from "./images/pilot-Dan.png";
import slideImage9 from "./images/pilot-CJ.png";
import TypographyBold from "../TypographyBold";
import PilotsGrid from "./components/PilotsGrid/pilots-grid";

const pilots = [
  {
    name: "DAVE MACKAY",
    role: "CHIEF PILOT",
    flyingSince: 1977,
    noOfTypesFlown: "150+",
    mainTypesFlown: ["Harrier", "Boeing 747", "Airbus 340"],
    totalExperience: "15,000+ HOURS",
    image: slideImage1,
  },
  {
    name: "KELLY LATIMER ",
    role: "PILOT",
    flyingSince: 1989,
    noOfTypesFlown: "50+",
    mainTypesFlown: ["T-38", "C-141", "C-17", "T-34", "B-747SP"],
    totalExperience: "7000+ HOURS",
    image: slideImage2,
  },
  {
    name: "NICOLA PECILE",
    role: "PILOT",
    flyingSince: 1991,
    noOfTypesFlown: "170+",
    mainTypesFlown: ["Tornado FMK.3 ADV,", "NH-500E"],
    totalExperience: "8000+ HOURS",
    image: slideImage3,
  },
  {
    name: "MICHAEL MASUCCI",
    role: "PILOT",
    flyingSince: 1982,
    noOfTypesFlown: "80+",
    mainTypesFlown: ["U-2", "F-16", "T-38", "Citation X"],
    totalExperience: "10000+ HOURS",
    image: slideImage5,
  },
  {
    name: "CJ STURCKOW",
    role: "PILOT",
    flyingSince: 1984,
    noOfTypesFlown: "65+",
    mainTypesFlown: ["F/A-18", "T-38", "Space Shuttle"],
    totalExperience: "8700+ HOURS",
    image: slideImage9,
  },
  {
    name: "Jameel Janjua",
    role: "PILOT",
    flyingSince: 1995,
    noOfTypesFlown: "60+",
    mainTypesFlown: ["CF-18", "F-16", "Tornado GR4", "F-15", "Learjet"],
    totalExperience: "4000+ HOURS",
    image: slideImage7,
  },
  {
    name: "Dan Alix",
    role: "PILOT",
    flyingSince: 2002,
    noOfTypesFlown: "37+",
    mainTypesFlown: ["F-16", "F-35"],
    totalExperience: "2500+ HOURS",
    image: slideImage8,
  },
  {
    name: "Andy Edgell",
    role: "PILOT",
    flyingSince: 1999,
    noOfTypesFlown: "65+",
    mainTypesFlown: ["F-35 Lightning", "Harrier GR-7/9", "F/A-18 Hornet"],
    totalExperience: "3000+ HOURS",
    image: slideImage6,
  },
];

const OurPilotsSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.titleWrapper}>
          <SectionTitle>
            Our pilots go
            <TypographyBold> above and beyond.</TypographyBold>
          </SectionTitle>
        </div>
        <p className={styles.description}>
          As the spaceline for Earth, we’re pleased to offer you an elegant,
          spaceflight system designed for safety, reusability and customer
          experience. You’ll be in the expert hands of our highly experienced
          pilots, all with long flight careers behind them.
        </p>
        <div className={styles.pilotsGalleryWrapper}>
          <PilotsGallery pilots={pilots} />
        </div>
        {/* Mobile grid list */}
        <PilotsGrid pilots={pilots} />
      </Container>
    </section>
  );
};

export default OurPilotsSection;
