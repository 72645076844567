import React from "react";

export default function useIsMobileScreen(breakpoint = 480) {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  React.useEffect(
    function checkScreenSize() {
      function handleResize() {
        setIsSmallScreen(window.innerWidth <= breakpoint);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    [breakpoint]
  );

  return isSmallScreen;
}
