import React from 'react';
import Container from '../Container';
import flightpathImage from './images/flightpath-desk.png';
import * as styles from './spaceflight-section.module.css';
import Flightpath from './components/Flightpath';


const SpaceflightSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.content}>
            <p className={styles.contentParagraph}>
              Our unique and innovative Spaceflight System enables you and your fellow astronauts to enjoy the most thrilling and awe-inspiring journey of your life, in unparalleled comfort and ease.
            </p>
          </div>
          <div>
            {/* Desktop image */}
            <div className={styles.flightpathWrapper}>
              <Flightpath  /> 
            </div>
            {/* Mobile image */}
            <div className={styles.imageWrapper}>
              <img className={styles.image} src={flightpathImage} alt='Flight path'/>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SpaceflightSection;
