import React from 'react';
import * as styles from './unity-video-section.module.css';
import video from './videos/220209_U22_Sizzle_DB.mp4';
import mutedIcon from './images/muted.svg';
import unmutedIcon from './images/unmuted.svg';
import classnames from 'classnames';

const UnityVideoSection = () => {
  const [muted, setMuted] = React.useState(true);
  const [isMutedButtonHidden, setIsMutedButtonHidden] = React.useState(true);

  React.useEffect(() => {
    let timer = setTimeout(() => setIsMutedButtonHidden(false), 800);

    const onMove = () => {
      setIsMutedButtonHidden(false);

      clearTimeout(timer);

      timer = setTimeout(() => {
        setIsMutedButtonHidden(true);
      }, 800);
    };

    document.addEventListener('pointermove', onMove);
    document.addEventListener('touchmove', onMove);

    return () => {
      document.removeEventListener('pointermove', onMove);
      document.removeEventListener('touchmove', onMove);
    };
  }, []);

  const mute = () => setMuted(true);
  const unmute = () => setMuted(false);

  return (
    <section className={styles.container}>
      <video className={styles.video} autoPlay loop muted={muted} playsInline>
        <source src={video} type='video/mp4' />
      </video>
      {muted ? (
        <button type='button' className={classnames(styles.unmuteButton, 'gtm-wb-sections-spaceflight-sound-toggle-spaceflight')} onClick={unmute}>
          <img className={styles.icon} src={mutedIcon} alt='Muted' />
        </button>
      ) : (
        <button type='button' className={classnames(styles.muteButton, 'gtm-wb-sections-spaceflight-sound-toggle-spaceflight', {
          [styles.hidden]: isMutedButtonHidden
        })} onClick={mute}>
          <img className={styles.icon} src={unmutedIcon} alt='Unmuted' />
        </button>
      )}
    </section>
  );
};

export default UnityVideoSection;
