import React, { useState, useRef } from "react";
import * as styles from "./pilots-grid.module.css";
import classNames from "classnames";
import useClickOutside from "../../../../utils/useClickOutside";

export default function PilotsGrid({ pilots }) {
  return (
    <div className={styles.pilotsGrid}>
      {pilots.map((pilot, index) => (
        <div className={styles.pilotsGridItem} key={index}>
          <PilotCard pilot={pilot} />
        </div>
      ))}
    </div>
  );
}

function PilotCard({ pilot }) {
  const cardRef = useRef(null);
  const [showDetail, setShowDetail] = useState(false);
  useClickOutside(cardRef, () => setShowDetail(false));
  const handleShowDetail = () => setShowDetail((prev) => !prev);

  const animation = showDetail ? styles.detailsVisible : styles.detailsHidden;

  return (
    <div
      className={styles.pilotsGridItemInner}
      onClick={handleShowDetail}
      ref={cardRef}
    >
      <div>
        <img
          src={pilot.image}
          className={styles.pilotsGridItemImage}
          alt={pilot.alt}
        />
      </div>
      <div className={classNames(styles.pilotsInfo, animation)}>
        <div className={styles.pilotsGridItemInfo}>
          <div className={styles.pilotsGridItemInfoName}>{pilot.name}</div>
          <div className={styles.pilotsGridItemInfoRole}>{pilot.role}</div>
        </div>

        {/* <!-- Details --> */}

        <div className={styles.detailsContainer}>
          <div className={styles.infoRow}>
            <div className={styles.flyingSince}>
              <div className={styles.infoRowTitle}>FLYING SINCE</div>
              <div>{pilot.flyingSince}</div>
            </div>
          </div>
          <div className={styles.infoRow}>
            {pilot.noOfTypesFlown && (
              <div className={styles.noOfTypesFlown}>
                <div className={styles.infoRowTitle}>NO OF TYPES FLOWN</div>
                <div>{pilot.noOfTypesFlown}</div>
              </div>
            )}
            {pilot.detailedNoOfTypesFlown &&
              pilot.detailedNoOfTypesFlown.length > 0 && (
                <div className={styles.detailedNoOfTypesFlown}>
                  <div className={styles.infoRowTitle}>NO OF TYPES FLOWN</div>
                  <div>
                    {pilot.detailedNoOfTypesFlown.map((type, index) =>
                      pilot.detailedNoOfTypesFlown.length - 1 !== index
                        ? `${type}, `
                        : type
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className={styles.infoRow}>
            <div className={styles.mainTypesFlown}>
              <div className={styles.infoRowTitle}>MAIN TYPES FLOWN</div>
              <div className={styles.mainTypesFlownValues}>
                {pilot.mainTypesFlown.map((type, index) =>
                  pilot.mainTypesFlown.length - 1 !== index ? `${type}, ` : type
                )}
              </div>
            </div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.totalExperience}>
              <div className={styles.infoRowTitle}>TOTAL EXPERIENCE</div>
              <div>{pilot.totalExperience}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
