import React from 'react';
import Gallery from './components/Gallery';

const GallerySection = () => {
  return (
    <section>
      <Gallery />
    </section>
  );
};

export default GallerySection;
