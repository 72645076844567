import React, { useMemo, useState } from "react";
import * as styles from "./pilots-gallery.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowIcon from "./images/arrow.svg";
import classnames from "classnames";
import useIsMobileScreen from "../../../../utils/useIsMobileScreen";

const ArrowButton = (props) => (
  <button type="button" {...props}>
    <img src={arrowIcon} alt="Arrow" />
  </button>
);

const PilotsGallery = ({ pilots }) => {
  return (
    <div
      className={classnames(
        styles.container,
        "gtm-wb-sections-spaceflight-pilots-carousel",
        styles.pilotsSection
      )}
    >
      <CustomSlider
        data={pilots}
        nextArrow={<ArrowButton />}
        prevArrow={<ArrowButton />}
        slidesToShow={3}
        breakpoint={{
          value: 992,
          slidesToShow: 2,
        }}
        renderItem={({ item: pilot, index }) => (
          <>
            <div key={index}>
              <div className={styles.slideInner}>
                <div className={styles.slideImageWrapper}>
                  <img
                    src={pilot.image}
                    className={styles.slideImage}
                    alt={pilot.name}
                  />
                </div>
                <div className={styles.slideInfo}>
                  <div className={styles.header}>
                    <div className={styles.name}>{pilot.name}</div>
                    <div className={styles.role}>{pilot.role}</div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.flyingSince}>
                      <div className={styles.infoRowTitle}>FLYING SINCE</div>
                      <div>{pilot.flyingSince}</div>
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    {pilot.noOfTypesFlown && (
                      <div className={styles.noOfTypesFlown}>
                        <div className={styles.infoRowTitle}>
                          NO OF TYPES FLOWN
                        </div>
                        <div>{pilot.noOfTypesFlown}</div>
                      </div>
                    )}
                    {pilot.detailedNoOfTypesFlown &&
                      pilot.detailedNoOfTypesFlown.length > 0 && (
                        <div className={styles.detailedNoOfTypesFlown}>
                          <div className={styles.infoRowTitle}>
                            NO OF TYPES FLOWN
                          </div>
                          <div>
                            {pilot.detailedNoOfTypesFlown.map((type, index) =>
                              pilot.detailedNoOfTypesFlown.length - 1 !== index
                                ? `${type}, `
                                : type
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.mainTypesFlown}>
                      <div className={styles.infoRowTitle}>
                        MAIN TYPES FLOWN
                      </div>
                      <div className={styles.mainTypesFlownValues}>
                        {pilot.mainTypesFlown.map((type, index) =>
                          pilot.mainTypesFlown.length - 1 !== index
                            ? `${type}, `
                            : type
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div className={styles.totalExperience}>
                      <div className={styles.infoRowTitle}>
                        TOTAL EXPERIENCE
                      </div>
                      <div>{pilot.totalExperience}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      ></CustomSlider>
    </div>
  );
};

function CustomSlider({
  data,
  nextArrow,
  prevArrow,
  renderItem,
  slidesToShow,
  breakpoint,
}) {
  const [page, setPage] = useState(0);
  const isSmallScreen = useIsMobileScreen(breakpoint.value);
  const elements = useMemo(() => {
    const slides = isSmallScreen ? breakpoint.slidesToShow : slidesToShow;
    return splitArray(data, slides);
  });

  const items = elements[page];

  const incrementPage = () => setPage((p) => p + 1);
  const decrementPage = () => setPage((p) => p - 1);

  return (
    <>
      <div className={styles.sliderContainer}>
        {page !== 0 && (
          <div>
            <button
              className={classnames(
                styles.sliderButton,
                styles.sliderLeftButton
              )}
              onClick={decrementPage}
            >
              {prevArrow}
            </button>
          </div>
        )}
        <div className={styles.sliderElements}>
          {items.map((item, i) => renderItem({ item, index: i }))}
        </div>
        {page < elements.length - 1 && (
          <div>
            <button
              className={classnames(
                styles.sliderButton,
                styles.sliderRightButton
              )}
              onClick={incrementPage}
            >
              {nextArrow}
            </button>
          </div>
        )}
      </div>
      <ul className={styles.slickContainer}>
        {[...Array(elements.length)].map((_, i) => (
          <li className={styles.slick}>
            <button
              onClick={() => setPage(i)}
              className={`${styles.slickButton} ${
                i === page ? styles.slickButtonActive : ""
              } `}
            ></button>
          </li>
        ))}
      </ul>
    </>
  );
}

function splitArray(data, size) {
  const result = [];

  for (let i = 0; i < data.length; i += size) {
    const subArray = data.slice(i, i + size);
    result.push(subArray);
  }

  return result;
}

export default PilotsGallery;
