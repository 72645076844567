import React from 'react';
import * as styles from './world-class-safety-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import TypographyBold from '../TypographyBold';
import image from './images/sirisha-bandla.jpg';
import mobileImage from './images/sirisha-bandla-mobile.jpg';


const WorldClassSafetySection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.titleWrapper}>
          <SectionTitle>
            World-class
            <TypographyBold> safety.</TypographyBold>
          </SectionTitle>
        </div>
        <div className={styles.description}>
          <p>
            At Virgin Galactic, safety has always been our North Star and an ethos that’s deeply embedded into our culture, evidenced by an exhaustive test flight program and highly experienced operational teams drawn from the world of aerospace and aviation.
          </p>
          <p>
            The Virgin Galactic spaceflight system has been specifically designed to reduce and make risk more manageable, throughout the entire flight.
          </p>
          <p>
            Vital to this is an air-launched, winged and piloted spaceship with a fully controllable propulsion system, enabling us to cut a flight short, safely and comfortably at any stage.
          </p>
        </div>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <picture className={styles.imageWrapper}>
              <source srcSet={mobileImage} media='(max-width: 768px)'/>
              <img className={styles.image} src={image} alt='Sirisha Bandla - Virgin Galactic Astronaut 004'/>
            </picture>
          </div>
          <div>
            <figure className={styles.quoteWrapper}>
              <blockquote>
                <p className={styles.quote}>
                  “Feeling the power of the motor behind me, and seeing the sky change from blue to black ahead – it was an experience unlike any I’ve ever had. While the speed and force was exhilarating, I felt safe and comfortable at all times.”
                </p>
              </blockquote>
              <figcaption>
                <p className={styles.author}>Sirisha Bandla - </p>
                <p className={styles.role}>Virgin Galactic Astronaut 004</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WorldClassSafetySection;
