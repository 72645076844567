// extracted by mini-css-extract-plugin
export var container = "world-class-safety-section-module--container--TO6eg";
export var titleWrapper = "world-class-safety-section-module--title-wrapper---SHWy";
export var description = "world-class-safety-section-module--description--LQqhW";
export var grid = "world-class-safety-section-module--grid--+Rikd";
export var imageContainer = "world-class-safety-section-module--image-container--ve-dn";
export var imageWrapper = "world-class-safety-section-module--image-wrapper--Itayv";
export var image = "world-class-safety-section-module--image--fEQ+l";
export var quoteWrapper = "world-class-safety-section-module--quote-wrapper--kBcWn";
export var quote = "world-class-safety-section-module--quote--3U6H2";
export var author = "world-class-safety-section-module--author--lEIzy";
export var role = "world-class-safety-section-module--role--swBYK";