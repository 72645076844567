import React from 'react';
import * as styles from './gallery.module.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowIcon from './images/arrow.svg';
import mobArrowIcon from './images/mob-arrow.svg';
import slideImage1 from './images/image-1.jpg';
import slideMobImage1 from './images/image-1-mob.jpg';
import slideImage2 from './images/image-2.jpg';
import slideMobImage2 from './images/image-2-mob.jpg';
import slideImage3 from './images/image-3.jpg';
import slideMobImage3 from './images/image-3-mob.jpg';
import slideImage4 from './images/image-4.jpg';
import slideMobImage4 from './images/image-4-mob.jpg';
import slideImage5 from './images/image-5.jpg';
import slideMobImage5 from './images/image-5-mob.jpg';
import slideImage6 from './images/image-6.jpg';
import slideMobImage6 from './images/image-6-mob.jpg';
import slideImage7 from './images/image-7.jpg';
import slideMobImage7 from './images/image-7-mob.jpg';
import slideImage8 from './images/image-8.jpg';
import slideMobImage8 from './images/image-8-mob.jpg';
import slideImage9 from './images/image-9.jpg';
import slideMobImage9 from './images/image-9-mob.jpg';
import Container from '../../../Container';
import classnames from 'classnames';

const slides = [
  {
    description: 'VMS Eve is a custom-built, four-engine, dual-fuselage jet carrier aircraft with a unique high-altitude, heavy-payload capability. It is also the world’s largest all-carbon aviation vehicle in service.',
    image: slideImage1,
    mobImage: slideMobImage1,
    alt: 'Unity',
  },
  {
    description: 'Our hybrid propulsion system combines the inherent stability of a solid rocket motor and the controllability of a liquid rocket motor. It is simpler and safer by design.',
    image: slideImage2,
    mobImage: slideMobImage2,
    alt: 'Unity',
  },
  {
    description: 'Your individually size-adjusted seat has been built to enhance your comfort and experience during each stage of flight.',
    image: slideImage3,
    mobImage: slideMobImage3,
    alt: 'Unity Seats',
  },
  {
    description: 'A minute of high-octane, high-g euphoria is followed by absolute silence, absolute space.',
    image: slideImage4,
    mobImage: slideMobImage4,
    alt: 'Unity',
  },
  {
    description: '17 windows — more than any other commercial spacecraft in history – with built-in hand grabs to optimize zero-g viewing.',
    image: slideImage5,
    mobImage: slideMobImage5,
    alt: 'Unity windows',
  },
  {
    description: 'Astronauts frequently report a cognitive shift in awareness and perspective brought about by viewing the Earth from space. This has become known as the Overview Effect.',
    image: slideImage6,
    mobImage: slideMobImage6,
    alt: 'Astronaut',
  },
  {
    description: 'While in space, astronauts are free to leave their seats for the effortless freedom of zero gravity.',
    image: slideImage7,
    mobImage: slideMobImage7,
    alt: 'Flying astronauts',
  },
  {
    description: 'Virgin Galactic currently offsets emissions for each spaceflight, and we are always working towards innovations that make our flights and daily operations more sustainable.',
    image: slideImage8,
    mobImage: slideMobImage8,
    alt: 'Unity',
  },
  {
    description: 'Our engineering teams are hard at work to quickly bring more spaceships online and facilitate your spaceflight.',
    image: slideImage9,
    mobImage: slideMobImage9,
    alt: 'Unity',
  },
];

const ArrowButton = (props) => (
  <button type='button' {...props}>
    <img src={arrowIcon} alt='Arrow'/>
    <img src={mobArrowIcon} alt='Arrow'/>
  </button>
);

const Gallery = () => {
  const settings = {
    fade: true,
    speed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowButton />,
    prevArrow: <ArrowButton />,
  };

  return (
    <div className={classnames(styles.container, 'gtm-wb-sections-spaceflight-photo-carousel')}>
      <Slider {...settings}>
        {slides.map((item, index) => (
          <div key={index}>
            <div className={styles.slideInner}>
              <picture className={styles.slideImageWrapper}>
                <source srcSet={item.mobImage} media='(max-width: 768px)'/>
                <img src={item.image} className={styles.slideImage} alt={item.alt} />
              </picture>
              <div className={styles.slideDescription}>
                <Container>
                  <p className={styles.slideDescriptionParagraph}>
                    {item.description}
                  </p>
                </Container>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
