// extracted by mini-css-extract-plugin
export var pilotsGrid = "pilots-grid-module--pilots-grid--mwWm5";
export var name = "pilots-grid-module--name--R-EOf";
export var role = "pilots-grid-module--role--R94bO";
export var infoRow = "pilots-grid-module--info-row---yIZj";
export var flyingSince = "pilots-grid-module--flying-since--rSD4C";
export var noOfTypesFlown = "pilots-grid-module--no-of-types-flown--ilgz1";
export var totalExperience = "pilots-grid-module--total-experience--XE3J0";
export var pilotsInfo = "pilots-grid-module--pilots-info--RHVyD";
export var detailsVisible = "pilots-grid-module--details-visible--VL4kr";
export var detailsHidden = "pilots-grid-module--details-hidden--enjKN";
export var pilotsGridItem = "pilots-grid-module--pilots-grid-item--9bn3p";
export var pilotsGridItemInner = "pilots-grid-module--pilots-grid-item-inner--H5usn";
export var pilotsGridItemImage = "pilots-grid-module--pilots-grid-item-image--D4ZuH";
export var pilotsGridItemInfoName = "pilots-grid-module--pilots-grid-item-info-name--dOvqy";
export var pilotsGridItemInfoRole = "pilots-grid-module--pilots-grid-item-info-role--osNan";
export var detailsContainer = "pilots-grid-module--details-container--CjqDP";